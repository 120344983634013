import { forwardRef } from 'react';
import { ArrowForwardIos, Circle, Close } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import classNames from 'classnames';
import { isEmpty, noop } from 'lodash';
import { DateTime } from 'luxon';
import Notification from 'interfaces/Notification';
import './stylesheets/NotificationCard.scss';

type NotificationCardProps = {
    notification: Notification | null;
    isSnackbar?: boolean;
    onClose?: () => void;
};

const NotificationCard = forwardRef<HTMLDivElement, NotificationCardProps>(
    ({ notification, isSnackbar = false, onClose }, ref) => {
        const sentDate = DateTime.fromISO(notification?.sentDate ?? '');
        return (
            <div
                className={classNames('notification-card', {
                    priority: notification?.isPriority,
                    shadow: isSnackbar,
                })}
                ref={ref}
                data-testid="card-wrapper"
            >
                <div className="notification-card-layout">
                    <div className="w-100">
                        {!isSnackbar && !notification?.readDate && (
                            <Circle
                                className="unread-marker"
                                fontSize="small"
                            />
                        )}
                        <div className="d-flex justify-content-between align-items-center w-100">
                            <span className="notification-card-subject">
                                {notification?.subject}
                            </span>
                            <span className="notification-card-date">
                                {sentDate.isValid &&
                                    sentDate.toLocaleString({
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                    })}
                            </span>
                        </div>
                        <p className="notification-card-content">
                            {notification?.content}
                        </p>
                    </div>
                </div>
                {onClose && (
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={onClose}
                        data-testid="close-card"
                    >
                        <Close fontSize="small" />
                    </IconButton>
                )}
            </div>
        );
    }
);
export default NotificationCard;
