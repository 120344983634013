import React, { useContext, useEffect, useState } from 'react';
import { get } from 'lodash';
import { Spinner, Alert } from 'react-bootstrap';
import {
    getB2CConfig,
    getFeatureFlags,
    getGoogleApiKey,
    getURLsAndUploadConstants,
} from '../api/config';
import { RESOURCE_TYPE } from '../constants/identifiers';
import './config.css';
import { ConfigValues, FeatureFlags, Resources } from 'interfaces/Config';

interface IConfigContext {
    configValues: Partial<ConfigValues>;
    featureFlags: Partial<FeatureFlags>;
    resources: Partial<Resources>;
    googleApiKey?: string;
}

const ConfigContext = React.createContext<IConfigContext>({
    configValues: {},
    featureFlags: {},
    resources: {},
    googleApiKey: '',
});

const useConfigContext = () => {
    const { resources, ...values } = useContext(ConfigContext);
    return {
        ...values,
        resources: {
            PRODUCER_SELLING_GUIDE_URL: resources[RESOURCE_TYPE.PRODUCER_SELLING_GUIDE],
            REQUEST_PLAN_MATERIALS_URL: resources[RESOURCE_TYPE.PLAN_MATERIALS],
            DRUG_SEARCH_URL: resources[RESOURCE_TYPE.DRUG_SEARCH],
            PROVIDER_SEARCH_URL: resources[RESOURCE_TYPE.PROVIDER_SEARCH],
            CERTIFICATION_URL: resources[RESOURCE_TYPE.CERTIFICATION],
            AHIP_YEARS: resources[RESOURCE_TYPE.AHIP_YEARS],
            BUSINESS_STATES: resources[RESOURCE_TYPE.BUSINESS_STATES],
            LICENSE_PRE_AEP_DATE: resources[RESOURCE_TYPE.LICENSE_PRE_AEP],
            LICENSE_AEP_DATE: resources[RESOURCE_TYPE.LICENSE_AEP],
        },
    };
};

const ConfigProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [configValues, setConfigValues] = useState({});
    const [resources, setResources] = useState({});
    const [featureFlags, setFeatureFlags] = useState({});
    const [googleApiKey, setGoogleApiKey] = useState<string>();

    const getConfigurations = async () => {
        setIsLoading(true);
        const processResourceService = getURLsAndUploadConstants()
            .then((urlSet) => {
                setResources(urlSet);
            })
            .catch((err) => {
                throw err;
            });
        const processFlagService = getFeatureFlags()
            .then((urlSet = []) => {
                const flags = {};
                urlSet.forEach(({ name, isActive }) => {
                    flags[name] = isActive;
                });
                setFeatureFlags(flags);
            })
            .catch((err) => {
                throw err;
            });
        const retrieveConfigs = getB2CConfig().then((configs) => {
            setConfigValues(configs);
        });
        const retrieveGoogleApiKey = getGoogleApiKey().then((apiKey) => {
            setGoogleApiKey(apiKey);
        });
        await Promise.all([
            processResourceService,
            processFlagService,
            retrieveConfigs,
            retrieveGoogleApiKey,
        ]).finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getConfigurations();
    }, []);
    if (isLoading) {
        return (
            <div className="pre-loading">
                <Spinner variant="secondary" animation="border" />
            </div>
        );
    } else if (
        get(configValues, 'status', 200) >= 400 ||
        get(featureFlags, 'status', 200) >= 400 ||
        get(resources, 'status', 200) >= 400 ||
        get(googleApiKey, 'status', 200) >= 400
    ) {
        return (
            <div className="pre-loading text-start">
                <Alert variant="danger" className="mt-5">
                    There was a network error.
                    <br />
                    Please try refreshing or contact the system administrator.
                </Alert>
            </div>
        );
    }

    const value = {
        configValues,
        featureFlags,
        resources,
        googleApiKey,
    };
    return (
        <ConfigContext.Provider value={value}>
            {children}
        </ConfigContext.Provider>
    );
};

export { ConfigProvider, useConfigContext };
