import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Circle, Notifications } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { debounce, isEmpty, reduce, get } from 'lodash';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getUnreadNotificationsCount } from 'api/notifications';
import { getToken } from 'Auth';
import { useConfigContext } from 'contexts/ConfigProvider';
import { useNotificationContext } from 'contexts/NotificationsProvider';
import checkExpanded from './util/checkExpanded';
import { FEATURE_IDS, USER_TYPE } from '../../constants/identifiers';
import { useUserContext } from '../../contexts/user';
import { toUpperCaseFirstCharacterOnly } from '../../utils/name';
import { NextButton } from '../Common/Button/Button';
import './stylesheets/Header.scss';

export default function Header({ handleToggle, onNotificationIconClick }) {
    const [unreadNotificationsCount, setUnreadNotificationsCount] =
        useState<number>(0);
    const { user } = useUserContext();
    const { featureFlags } = useConfigContext();
    const { hubConnection } = useNotificationContext();
    const { instance } = useMsal();

    const logout = async () => {
        instance.logoutRedirect();
    };

    let nameString = '';
    if (!isEmpty(user)) {
        nameString =
            user.userType !== USER_TYPE.Broker
                ? toUpperCaseFirstCharacterOnly(
                      get(user, 'userName', '').split(' ')[0]
                  )
                : reduce(
                      get(user, 'userName', '').split(' '),
                      (cumu, curr) => {
                          return `${cumu} ${toUpperCaseFirstCharacterOnly(
                              curr
                          )}`;
                      },
                      ''
                  );
    }

    const getUnreadNotifications = async () =>
        getToken(instance).then((token) => {
            getUnreadNotificationsCount(token).then((response) => {
                if (response.ok) {
                    response.json().then(({ count }) => {
                        setUnreadNotificationsCount(count);
                    });
                }
            });
        });

    useEffect(() => {
        getUnreadNotifications();
    }, []);

    useEffect(() => {
        // fire initial expanded status based on collapse situation of initial load
        const idString = 'main-navigation';
        handleToggle(checkExpanded(idString));
        // listen to resize as bootstrap doesn't fire anything when collapsing happens
        const handleResize = debounce(() => {
            handleToggle(checkExpanded(idString));
        }, 300);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleToggle]);

    useEffect(() => {
        let intervalId;
        if (featureFlags[FEATURE_IDS.REALTIME_NOTIFICATIONS]) {
            hubConnection?.on('ReceiveNotification', () => {
                getUnreadNotifications();
            });
        } else {
            // if real-time notifications is not enabled, fallback to regularly polling
            // for the unread notifications count every 5 minutes
            intervalId = setInterval(getUnreadNotifications, 300000);
        }
        hubConnection?.on('AcknowledgeNotification', () => {
            getUnreadNotifications();
        });

        return () => clearInterval(intervalId);
    }, [hubConnection]);

    return (
        <div className="mainheader">
            <Navbar
                id="main-navigation"
                aria-label="Header"
                collapseOnSelect
                expand="lg"
                onToggle={handleToggle}
                style={{ padding: '0.5rem 1rem' }}
            >
                <Navbar.Brand className="logo-icon" as={Link} to="/">
                    <img
                        src="/assets/ahc_logo.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        alt="Agent Portal"
                    />
                    <span className="ps-2">Alignment Healthcare</span>
                </Navbar.Brand>
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav-menu"
                    data-target=".navbar-toggle"
                />
                <Navbar.Collapse
                    id="responsive-navbar-nav"
                    className="navbar-toggle"
                >
                    <div className="searchwrap justify-content-center">
                        {/* TODO: Enable once implemented */}
                        {/* <SearchBar /> */}
                    </div>
                    <Nav className="d-flex align-items-center gap-1">
                        {featureFlags[FEATURE_IDS.NOTIFICATIONS] && (
                            <IconButton
                                onClick={() => {
                                    if (
                                        !featureFlags[
                                            FEATURE_IDS.REALTIME_NOTIFICATIONS
                                        ]
                                    ) {
                                        getUnreadNotifications();
                                    }
                                    onNotificationIconClick();
                                }}
                                sx={{ position: 'relative' }}
                            >
                                {unreadNotificationsCount > 0 && (
                                    <div id="icon-unread-marker-wrapper">
                                        <span
                                            id="notifications-icon-count"
                                            className={`${
                                                unreadNotificationsCount > 9
                                                    ? 'multi-digit'
                                                    : 'single-digit'
                                            }`}
                                        >
                                            {unreadNotificationsCount > 99
                                                ? '99+'
                                                : unreadNotificationsCount}
                                        </span>
                                        <Circle id="icon-unread-marker" />
                                    </div>
                                )}
                                <Notifications sx={{ fill: 'white' }} />
                            </IconButton>
                        )}
                        <Nav.Link
                            className="button user"
                            as={Link}
                            to="/user-profile"
                            eventKey={99}
                        >
                            <span>Welcome {nameString}</span>
                        </Nav.Link>
                        <NextButton
                            color="success"
                            className="shadow-sm button logout ms-3"
                            onClick={logout}
                            aria-label="log out"
                        >
                            LOG OUT
                        </NextButton>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}
