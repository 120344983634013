import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import Notification from 'interfaces/Notification';
import NotificationType from 'enums/Notifications/NotificationType';
import { useNavigate } from 'react-router';
import './stylesheets/NotificationDetails.scss';
import { VIEW_STATE } from 'components/MyClients/constants/applications';

type NotificationDetailsProps = {
    notification: Notification | null;
};

const NotificationDetails = ({ notification }: NotificationDetailsProps) => {
    const navigate = useNavigate();

    const onClickAddressIssue = () => {
        switch (notification?.type) {
            case NotificationType.MbiVerification:
            case NotificationType.ApplicationSuccessMbiVerificationRequired: {
                const applicationId = notification?.actionMetadata?.applicationId;               
                navigate('/my-clients', {
                    state: {
                        pathName: '/my-clients',
                        selectedItem: 1,
                        refId: applicationId,
                        applicationView: VIEW_STATE.DETAILS,
                        showMBIVerification: true,
                        notificationType: notification?.type,
                    },
                });
            }
                break;
            default:
                break;
        }
    };
    return (
        <div id="notification-details">
            {!isEmpty(notification) ? (
                <>
                    <div className="d-flex flex-column gap-3 w-100">
                        <div id="notification-details-info">
                            <span>
                                {DateTime.fromISO(
                                    notification.sentDate
                                ).toLocaleString(DateTime.DATETIME_FULL)}
                            </span>
                            <span>
                                Source: {notification.source ?? 'Not Specified'}
                            </span>
                        </div>
                        <div>
                            <span id="notification-details-subject">
                                {notification.subject}
                            </span>
                        </div>
                    </div>
                    <p id="notification-details-content">
                        {notification.content}
                    </p>
                    {!isEmpty(notification?.actionMetadata) && (
                        <button id="notification-details-action" onClick={onClickAddressIssue}>
                            Address Issue
                        </button>
                    )}
                </>
            ) : (
                <>Select a notification.</>
            )}
        </div>
    );
};

export default NotificationDetails;
